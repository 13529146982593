
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import coalsaved from '../Components/img/img/coal.png';
import co2 from '../Components/img/img/CO2.png';
import ETP from '../Components/img/img/ETP.png';
import './EnvironmentalBenefits.css';

const EnvironmentalBenefits = () => {
  const [coalSaved, setCoalSaved] = useState(0);
  const [co2Avoided, setCo2Avoided] = useState(0);
  const [equivalentTreesPlanted, setEquivalentTreesPlanted] = useState(0);

  useEffect(() => {
    const fetchLifetimeEnergy = async () => {
      try {
        const response = await axios.get('http://fcic.cc:16223/energy/lifetime');
        const { BodyknitsLifetimeEnergy, SweeleeLifetimeEnergy } = response.data.data;

        const bodyknitsEnergy = parseFloat(BodyknitsLifetimeEnergy);
        const sweeleeEnergy = parseFloat(SweeleeLifetimeEnergy);
        // const totalLifetimeEnergy = bodyknitsEnergy + sweeleeEnergy;

        const coal1 = 0.4 * bodyknitsEnergy;
        const co21 = 0.475 * bodyknitsEnergy;
        const trees1 = (co21 * 1000) / 18.3 / 40;

        const coal2 = 0.4 * sweeleeEnergy;
        const co22 = 0.475 * sweeleeEnergy;
        const trees2 = (co22 * 1000) / 18.3 / 40;

        const coal=coal1+coal2;
        const co2=co21+co22;
        const trees=trees1+trees2;
        

        setCoalSaved(coal);
        setCo2Avoided(co2);
        setEquivalentTreesPlanted(trees);
      } catch (error) {
        console.error('Error fetching lifetime energy:', error);
      }
    };

    fetchLifetimeEnergy();
  }, []);

  return (
    <div className="Env-container">
      <div className="EB-row">
        <div className="EB-item">
          <img src={coalsaved} alt="Coal Saved" />
          <div className="value">{coalSaved.toFixed(2)} Tons</div>
          <div className="label">Coal Saved</div>
        </div>
        <div className="EB-item">
          <img src={co2} alt="CO2 Avoided" />
          <div className="value">{co2Avoided.toFixed(2)} Tons</div>
          <div className="label">CO2 Avoided</div>
        </div>
        <div className="EB-item">
          <img src={ETP} alt="Equivalent Trees Planted" />
          <div className="value">{equivalentTreesPlanted.toFixed(2)} Nos</div>
          <div className="label">Equivalent Trees Planted</div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentalBenefits;
