// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Env-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    margin-top: 10%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .EB-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
  }
  
  .EB-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .EB-item img {
    max-width: 90px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .value {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .label {
    font-size: 1rem;
    font-weight: 600;
    color: #7f8c8d;
  }
  
  @media (max-width: 768px) {
    .EB-row {
      flex-direction: column;
      align-items: center;
    }
  
    .EB-item {
      margin-bottom: 20px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/EnvironmentalBenefits.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,wCAAwC;EAC1C;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE;MACE,sBAAsB;MACtB,mBAAmB;IACrB;;IAEA;MACE,mBAAmB;IACrB;EACF","sourcesContent":["\r\n.Env-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 20px;\r\n    background-color: white;\r\n    border-radius: 12px;\r\n    margin-top: 10%;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .EB-row {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    width: 100%;\r\n    max-width: 800px;\r\n  }\r\n  \r\n  .EB-item {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    text-align: center;\r\n  }\r\n  \r\n  .EB-item img {\r\n    max-width: 90px;\r\n    height: auto;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .value {\r\n    font-size: 1.2rem;\r\n    font-weight: bold;\r\n    color: #2c3e50;\r\n  }\r\n  \r\n  .label {\r\n    font-size: 1rem;\r\n    font-weight: 600;\r\n    color: #7f8c8d;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .EB-row {\r\n      flex-direction: column;\r\n      align-items: center;\r\n    }\r\n  \r\n    .EB-item {\r\n      margin-bottom: 20px;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
