
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import EnvironmentalBenefits from './Components/EnvironmentalBenefits';
function App() {

  return (
   <div>
    <EnvironmentalBenefits/>
   </div>
  );
}

export default App;
